<template>
  <div>
    <ManageScholarshipsPhD1 />
  </div>
</template>
<script>
import ManageScholarshipsPhD1 from "@/components/ManageScholarshipsPhD1/ManageScholarshipsPhD1";
export default {
  components: {
    ManageScholarshipsPhD1,
  },
  created() {},
};
</script>
